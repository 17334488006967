.exam-card {
    width: 330px;
    height: 160px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 1.3rem 1.3em;
    border: solid 2px transparent;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: all 0.2s;
    box-shadow: 1px 3px 8px #e0e0e0;
    background-color: white;
} .exam-card:hover {
    cursor: pointer;
    border-color: var(--secondary);
}

.upSide-card {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

.date {
    font-size: 0.8rem;
    margin-right: auto;
    color: #505050;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
    margin: 0.5rem 0;
}

.exam-card-name {
    width: 100%;
}

.exam-card-name p {
    font-weight: 600;
    color: #909090;
    margin-top: -0.1rem;
}

.exam-result {
    margin-bottom: 0.6rem;
}